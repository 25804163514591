import { useEffect, useState } from "react";

// material ui
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./lib/theme";

// react-toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// react-helmet
import { HelmetProvider, Helmet } from "react-helmet-async";

// axios lib
import "./lib/axiosLib";

// react-router-dom
import { BrowserRouter, Routes, Route } from "react-router-dom";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetail } from "./utils/reduxSlice/userSlice";
import { updateBrandingDetail } from "./utils/reduxSlice/brandingSlice";

// Error Boundary import
import ErrorBoundary from "./components/ErrorBoundary";

// Layout import
import Layout from "./components/Layout";

// ScrollToTop import
import ScrollToTop from "./components/ScrollToTop";

// pages import
import Login from "./pages/Login";
import Custom404 from "./pages/404";
import Dashboard from "./pages/Dashboard";
import Admin from "./pages/admin";
import Settings from "./pages/settings";

// hooks import
import userDetailApi from "./hooks/GET/userDetail";
import brandingApi from "./hooks/GET/branding";

// utils import
import { getJWTTokenFromCookie } from "./utils";

// Loading component import
import Loading from "./components/Loading";


function App() {
  const dispatch = useDispatch();

  
  // getting user detail start
  const fetchUserDtlTrg = useSelector((state) => state.temp.fetchUserDtlTrg);

  useEffect(() => {
    const authToken = getJWTTokenFromCookie();
    if (authToken) {
      const jwtToken = authToken.split(" ")[1];
      const payLoad = atob(jwtToken.split(".")[1]);
      const id = JSON.parse(payLoad).id;
      userDetailApi(id)
        .then((data) => dispatch(updateUserDetail(data)))
        .catch((error) => console.log(error));
    }
  }, [fetchUserDtlTrg]);
  // getting user detail end

  // getting branding detail start
  const fetchBrandingDtlTrg = useSelector(
    (state) => state.temp.fetchBrandingDtlTrg
  );
  const brandingDtl = useSelector((state) => state.branding);

  useEffect(() => {
    brandingApi()
      .then((data) => dispatch(updateBrandingDetail(data)))
      .catch((error) => console.log(error));
  }, [fetchBrandingDtlTrg]);
  // getting branding detail end

  //viewport
  const [vp, setLimitReached] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.screen.width <= 599)
        setLimitReached(true);
      else 
        setLimitReached(false);

    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);
  })

  return (
    <ThemeProvider theme={theme(brandingDtl)}>
      <CssBaseline />
      <ErrorBoundary>
        <ToastContainer limit={5} autoClose={3000} />
        <HelmetProvider>
          <Helmet>
            <link rel="icon" href={brandingDtl.pageIconUrl || "/favicon.ico"} />
            {vp ? <meta name="viewport" content="width=599" /> : <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />}
          </Helmet>
          {!brandingDtl.id ? (
            <Loading />
          ) : (
            <BrowserRouter>
              <ScrollToTop />
              <Routes>
                <Route exact path="/" element={<Login />} />

                <Route exact path="/" element={<Layout />}>
                  <Route exact path="dashboard" element={<Dashboard />} />
                  <Route exact path="admin" element={<Admin />} />
                  <Route exact path="settings" element={<Settings />} />
                </Route>

                <Route exact path="/404" element={<Custom404 />} />
                <Route path="*" element={<Custom404 />} />
              </Routes>
            </BrowserRouter>
          )}
        </HelmetProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
