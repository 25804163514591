import React from "react";
import { Backdrop, LinearProgress, Stack, Typography } from "@mui/material";

function Loading() {
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#000",
        }}
        open={true}
      >
        <Stack alignItems="center" spacing={1}>
          <Typography>Loading...</Typography>
          <LinearProgress color="primary" sx={{ width: "50vw" }} />
        </Stack>
      </Backdrop>
    </div>
  );
}

export default Loading;
