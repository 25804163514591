import axios from "axios";
import { getJWTTokenFromCookie, clearCookieData } from "../utils";

axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = getJWTTokenFromCookie();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      clearCookieData(); // remove all cookie
      window.location = "/";
    }
    return Promise.reject(error);
  }
);

export default axios;
