import axios from "axios";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";

const getEventApi = async (page,rowsPerPage,columnFilters) => {
  try {
    if (columnFilters.length > 0){

      var col = columnFilters.map(x => x.id);
      var filterValue = columnFilters.map(x => x.value);

      // createdAt
      const createdAtIndex = columnFilters.findIndex(obj => obj.id === 'createdAt');
      if (createdAtIndex !== -1) {
        let value = filterValue[createdAtIndex];
        const date = new Date(value.$d);
        if (isNaN(date)){
          col.splice(createdAtIndex, 1);
          filterValue.splice(createdAtIndex, 1);

        }else{
          const formattedDate = date.toISOString().slice(0, 10);
          filterValue[createdAtIndex] = formattedDate
        }
      }
    }
    const apiLink = `${endpoint}/api/event/`+ page + `/` + rowsPerPage 
                    + `?col=` + col + `&filterValue=` + filterValue ;
    const response = await axios.get(apiLink);
    const data = response.data;
    const dataArray = [];

    if (data) {
      data['rows'].forEach((elem) => {
        const obj = {
          id: elem.id,
          action: elem.action,
          status: elem.status,
          userId: elem.userId ? elem.userId : null,
          userName: elem.userName ? elem.userName : "",
          userRecordId: elem.userRecordId ? elem.userRecordId : null,
          tableId: elem.tableId ? elem.tableId : null ,
          tableName: elem.tableName ? elem.tableName : "" ,
          tableDatabaseName: elem.tableDatabaseName ? elem.tableDatabaseName : "" ,
          tableDatabaseRecordId: elem.tableDatabaseRecordId ? elem.tableDatabaseRecordId : null,
          existingRecord: JSON.parse(elem.existingRecord) ? JSON.parse(elem.existingRecord) : "" ,
          updateValue: JSON.parse(elem.updateValue) ? JSON.parse(elem.updateValue) : "" ,
          createdAt: elem.createdAt,
        };

        dataArray.push(obj);
      });
    }
    const totalResult = data['count']
    return [dataArray,totalResult];
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

export { getEventApi };
