import axios from "axios";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";
import { initialState } from "../../utils/reduxSlice/userSlice";

const userDetailApi = async (id) => {
  try {
    const userObj = { ...initialState, permissions: [] };
    const apiLink = `${endpoint}/api/user/${id}`;
    const response = await axios.get(apiLink);
    const data = response.data;

    if (data) {
      userObj.email = data.email;
      userObj.fullName = data.fullName;
      userObj.id = data.id;
      userObj.isAdmin = data.isAdmin;
      userObj.isDefault = data.isDefault;
      userObj.permissions =
        data.permissions.map((e) => ({
          id: e.id,
          name: e.name || "",
          description: e.description || "",
          databaseName: e.databaseName || "",
        })) || [];
    }

    return userObj;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

export default userDetailApi;
