import React, { useEffect, useState } from "react";
import { Paper, Grid, TextField, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import {
  emailValidation,
  endpoint,
  fullNameValidation,
  passwordValidation,
} from "../../config";
import { customToast } from "../../lib/toastLib";
import userDetailApi from "../../hooks/GET/userDetail";
import { incrementFetchUserDtlTrg } from "../../utils/reduxSlice/tempSlice";

function Profile() {
  const dispatch = useDispatch();

  // userId
  const userId = useSelector((state) => state.user.id);

  // state logic start
  const [state, setState] = useState({
    fullName: "",
    fullNameError: "",
    email: "",
    emailError: "",
    oldPassword: "",
    showOldPassword: false,
    newPassword: "",
    newPasswordError: "",
    showNewPassword: false,
    confirmNewPassword: "",
    confirmNewPasswordError: "",
    showConfirmNewPassword: false,
  });

  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      fullName: value,
      fullNameError:
        value && !value.match(fullNameValidation.regex)
          ? fullNameValidation.message
          : "",
    });
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      email: value,
      emailError:
        value && !value.match(emailValidation.regex)
          ? emailValidation.message
          : "",
    });
  };

  const handleOldPasswordChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      oldPassword: value,
    });
  };

  const handleShowOldPassword = () => {
    setState({ ...state, showOldPassword: !state.showOldPassword });
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      newPassword: value,
      newPasswordError:
        value && !value.match(passwordValidation.regex)
          ? passwordValidation.message
          : "",
    });
  };

  const handleShowNewPassword = () => {
    setState({ ...state, showNewPassword: !state.showNewPassword });
  };

  const handleConfirmNewPasswordChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      confirmNewPassword: value,
      confirmNewPasswordError:
        value && !value.match(passwordValidation.regex)
          ? passwordValidation.message
          : "",
    });
  };

  const handleShowConfirmNewPassword = () => {
    setState({
      ...state,
      showConfirmNewPassword: !state.showConfirmNewPassword,
    });
  };
  // state logic end

  // submit button disable state logic start
  const [isSubmitBtnDisbaled, setIsSubmitBtnDisbaled] = useState(true);

  useEffect(() => {
    setIsSubmitBtnDisbaled(
      (!state.fullName || state.fullNameError ? true : false) ||
        (!state.email || state.emailError ? true : false) ||
        (!state.oldPassword) ||
        (!state.newPassword || state.newPasswordError ? true : false) ||
        (!state.confirmNewPassword || state.confirmNewPasswordError
          ? true
          : false)
    );
  }, [state]);
  // submit button disable state logic end

  // fetching user detail to re-populate start
  useEffect(() => {
    if (userId) {
      userDetailApi(userId)
        .then((data) => {
          setState((state) => ({
            ...state,
            fullName: data.fullName,
            email: data.email,
          }));
        })
        .catch((error) => console.log(error));
    }
  }, [userId]);
  // fetching user detail to re-populate end

  // handle submit logic start
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // checking if new password matches confirm new password
    if (state.newPassword !== state.confirmNewPassword) {
      customToast.error("Please make sure your password matches");
      return -1;
    }

    const requestBody = {
      fullname: state.fullName,
      email: state.email,
      oldPassword: state.oldPassword,
      newPassword: state.newPassword,
      retypeNewPassword: state.confirmNewPassword,
    };
    setSubmitBtnLoading(true);
    axios
      .put(`${endpoint}/api/user/profile/${userId}`, requestBody)
      .then((res) => res.data)
      .then(() => {
        setSubmitBtnLoading(false);
        dispatch(incrementFetchUserDtlTrg()); // resulting to user detail fetch
        customToast.success("Your data have been recorded successfully");
        setState({
          ...state,
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        });
      })
      .catch((error) => {
        setSubmitBtnLoading(false);
        if (error.response) {
          customToast.error(error.response.data?.message);
        } else {
          customToast.error(error.message);
        }
      });
  };
  // handle submit logic end

  return (
    <Grid container justifyContent="center">
      <Grid
        item
        component={Paper}
        variant="outlined"
        sx={{ padding: 2 }}
        xs={12}
        sm={8}
        md={6}
      >
        <form onSubmit={handleSubmit} autoComplete="off">
          <TextField
            margin="normal"
            required
            fullWidth
            label="Full Name"
            name="fullName"
            value={state.fullName}
            onChange={handleFullNameChange}
            error={state.fullNameError ? true : false}
            helperText={state.fullNameError}
            disabled
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email"
            name="email"
            value={state.email}
            onChange={handleEmailChange}
            error={state.emailError ? true : false}
            helperText={state.emailError}
            disabled
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type={state.showOldPassword ? "text" : "password"}
            label="Old Password"
            name="oldPassword"
            value={state.oldPassword}
            onChange={handleOldPasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={handleShowOldPassword}>
                  {state.showOldPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type={state.showNewPassword ? "text" : "password"}
            label="New Password"
            name="newPassword"
            value={state.newPassword}
            onChange={handleNewPasswordChange}
            error={state.newPasswordError ? true : false}
            helperText={state.newPasswordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={handleShowNewPassword}>
                  {state.showNewPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type={state.showConfirmNewPassword ? "text" : "password"}
            label="Re-type New Password"
            name="confirmNewPassword"
            value={state.confirmNewPassword}
            onChange={handleConfirmNewPasswordChange}
            error={state.confirmNewPasswordError ? true : false}
            helperText={state.confirmNewPasswordError}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handleShowConfirmNewPassword}
                >
                  {state.showConfirmNewPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <LoadingButton
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            type="submit"
            disabled={isSubmitBtnDisbaled}
            loading={submitBtnLoading}
            sx={{ mt: 4 }}
          >
            Save
          </LoadingButton>
        </form>
      </Grid>
    </Grid>
  );
}

export default Profile;
