import axios from "axios";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";

const allTablesListApi = async () => {
  try {
    const apiLink = `${endpoint}/api/tables/all`;
    const response = await axios.get(apiLink);
    const data = response.data;

    const dataArray = [];
    if (data) {
      data.forEach((elem) => {
        const obj = {
          name: elem.table_name,
        };
        dataArray.push(obj);
      });
    }

    return dataArray;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

const tablesDetailApi = async (databaseName, page,rowsPerPage, columnFilters) => {
  try {
    if (columnFilters.length > 0){
      var col = columnFilters.map(x => x.id);
      var filterValue = columnFilters.map(x => encodeURIComponent(x.value));
      var filterVariant = columnFilters.map(x => x.filterVariant);

    }
    const apiLink = `${endpoint}/api/tables/` + databaseName + `/` + page + `/` + rowsPerPage
                    + `?col=` + col + `&filterValue=` + filterValue 
                    + `&filterVariant=` + filterVariant;

    const response = await axios.get(apiLink);
    const data = response.data;
    const dataArray = [];
    if (data.length !== 0){
        data.forEach((elem) => {
          const sorted = Object.keys(elem)
                          .reduce((acc, key) => ({
                              ...acc, [key]: elem[key]
                          }), {})
          dataArray.push(sorted);
        });
    }

    return dataArray;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

const tablesColumnsDatatypeApi = async (databaseName) => {
  try {
    const apiLink = `${endpoint}/api/tables/datatype/` + databaseName;
    const response = await axios.get(apiLink);
    const data = response.data;
    const obj = {}
    if (data) {
      data.forEach((elem) => {
        obj[elem.column_name] = { data_type: elem.data_type }
      });
    }

    return obj;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

const tablesColumnsSelectOptionsApi = async (databaseName,columnFilters) => {
  try {
    if (columnFilters.length > 0){
      var col = columnFilters.map(x => x.id);
      var filterValue = columnFilters.map(x => encodeURIComponent(x.value));
      var filterVariant = columnFilters.map(x => x.filterVariant);
    }

    const apiLink = `${endpoint}/api/tables/selectoptions/` + databaseName
                    + `?col=` + col + `&filterValue=` + filterValue
                    + `&filterVariant=` + filterVariant;
    const response = await axios.get(apiLink);
    const data = response.data;

    return data;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

// get primary key
const tablePrimaryKeyApi = async (databaseName) => {
  try {
    const apiLink = `${endpoint}/api/tables/pk/` + databaseName;
    const response = await axios.get(apiLink);
    const data = response.data;
    const array = data.map(({ column_name }) => column_name);

    return array
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

// get table total count
const tableTotalCountApi = async (databaseName, columnFilters) => {
  try {
    if (columnFilters.length > 0){
      var col = columnFilters.map(x => x.id);
      var filterValue = columnFilters.map(x => encodeURIComponent(x.value));
      var filterVariant = columnFilters.map(x => x.filterVariant);
    }

    const apiLink = `${endpoint}/api/tables/total/` + databaseName + `?col=` + col + `&filterValue=` + filterValue 
    + `&filterVariant=` + filterVariant;

    const response = await axios.get(apiLink);
    const data = response.data;

    return data[0].total
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

export { allTablesListApi, tablesDetailApi, tablesColumnsDatatypeApi,tablesColumnsSelectOptionsApi, tablePrimaryKeyApi, tableTotalCountApi };

