import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  IconButton,
  TextField,
  Switch,
  FormControlLabel,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  MenuItem,
  Chip,
  Checkbox,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import axios from "axios";
import bcrypt from "bcryptjs";
import {
  emailValidation,
  fullNameValidation,
  passwordValidation,
  endpoint,
} from "../../config";
import tablesApi from "../../hooks/GET/tables";

import { customToast } from "../../lib/toastLib";
import { useSelector, useDispatch } from "react-redux";
import { incrementFetchUserDtlTrg } from "../../utils/reduxSlice/tempSlice";

import postEventApi from "../../hooks/POST/event";
import getUpdateValue from "../../config/getUpdateValue.js"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function UsersDialog(props) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  // props value
  const {
    open,
    handleClose,
    dataId,
    dataFullName,
    dataEmail,
    dataPermissions,
    dataIsAdmin,
    dataPassword,
  } = props;
  // permissions list logic start
  const [permissionsList, setPermissionsList] = useState([]);

  //get logged-in user
  const userId = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.fullName);


  useEffect(() => {
    tablesApi()
      .then((data) => {
        setPermissionsList(data);
      })
      .catch((error) => console.log(error));
  }, []);
  // permissions list logic end

  // state logic start
  const initialState = {
    fullName: "",
    fullNameError: "",
    email: "",
    emailError: "",
    password: "",
    passwordError: "",
    showPassword: false,
    permissions: [],
    permissionError: "",
    isAdmin: false,
    confirmPassword: "",
    confirmPasswordError: "",
    showconfirmPassword: false
  };

  const [state, setState] = useState({ ...initialState });

  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      fullName: value,
      fullNameError:
        value && !value.match(fullNameValidation.regex)
          ? fullNameValidation.message
          : "",
    });
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      email: value,
      emailError:
        value && !value.match(emailValidation.regex)
          ? emailValidation.message
          : "",
    });
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      password: value,
      passwordError:
        value && !value.match(passwordValidation.regex)
          ? passwordValidation.message
          : "",
      confirmPasswordError: (state.confirmPassword && value !== state.confirmPassword) ? "Password mismatch" : ""
    });
  };

  const handleShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleShowConfirmPassword = () => {
    setState({ ...state, showconfirmPassword: !state.showconfirmPassword });
  }

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      confirmPassword: value,
      confirmPasswordError: (value && value !== state.password) ? "Password mismatch" : ""
    });
  };

  const handlePermissionsChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      permissions: typeof value === "string" ? value.split(",") : value,
      permissionError: (value.length === 0) ? "Select atleast one permission" : ""
    });
  };

  const handleIsAdminChange = (e) => {
    setState({ ...state, isAdmin: e.target.checked });
  };
  // state logic end

  // submit button disable state logic start
  const [isSubmitBtnDisbaled, setIsSubmitBtnDisbaled] = useState(true);

  useEffect(() => {
    setIsSubmitBtnDisbaled(
      (!state.fullName || state.fullNameError ? true : false) ||
      (!state.email || state.emailError ? true : false) ||
      ((!dataId && !state.password) || state.passwordError ? true : false) ||
      ((!dataId && !state.confirmPassword) || state.confirmPasswordError ? true : false) ||
      state.permissions.length === 0
    );
  }, [state]);
  // submit button disable state logic end

  // handle submit logic start
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const handleCatchLogic = (error) => {
      setSubmitBtnLoading(false);
      if (error.response) {
        customToast.error(error.response.data?.message);
      } else {
        customToast.error(error.message);
      }
    };

    const existingRecord = {
      fullName: props.dataFullName,
      email: props.dataEmail,
      isAdmin: props.dataIsAdmin,
      tables: props.dataPermissions.map(e => e.id),
      password: props.dataPassword,
      id: props.dataId
    }
    const requestBody = {
      fullName: state.fullName,
      email: state.email,
      isAdmin: state.isAdmin,
      ...(!dataId && { password: state.password }),
      ...(dataId &&
        state.password && {
        updatePassword: state.password,
        verifyPassword: state.password,
      }),
      // ...(dataId && {
      tables: state.permissions.map((e) => JSON.parse(e).id),
      // }),
      id: props.dataId
    };
    const { updatedExistingRecord,updatedUpdateValue } = getUpdateValue(existingRecord,requestBody)
    
    // compare password
    async function hashPassword(updatedUpdateValue,updatedExistingRecord) {
  
    if (updatedUpdateValue.updatePassword && updatedUpdateValue.verifyPassword){

      const validPassword = await bcrypt.compare(updatedUpdateValue.updatePassword,updatedExistingRecord.password);

      if (validPassword){
        delete updatedExistingRecord.password;
        delete updatedUpdateValue.updatePassword;
        delete updatedUpdateValue.verifyPassword;
      }
      else{
        updatedUpdateValue.updatePassword = await bcrypt.hash(updatedUpdateValue.updatePassword, 10)
        updatedUpdateValue.verifyPassword = await bcrypt.hash(updatedUpdateValue.verifyPassword, 10)

      }
    }
    else{
      delete updatedExistingRecord.password;
    }
    return {updatedUpdateValue,updatedExistingRecord};
  }
  (async () => {
    const newObj = await hashPassword(updatedUpdateValue,updatedExistingRecord)
    setSubmitBtnLoading(true);
    if (!dataId) {
      axios
        .post(`${endpoint}/api/user`, requestBody)
        .then((response) => {
          setSubmitBtnLoading(false);
          customToast.success("User added successfully");
          handleClose(true);
          requestBody.id = response.data.id;
          postEventApi({  action:'created user ' + requestBody.fullName,
                          status:'success',
                          updateValue:JSON.stringify(requestBody),
                          userId:userId,
                          userName:userName,
                          userRecordId:response.data.id,
                        })
        })
        .catch((error) => {
          handleCatchLogic(error)
          postEventApi({action:'created user ' + requestBody.fullName,
                        status:'failure',
                        updateValue:JSON.stringify(requestBody),
                        userId:userId,
                        userName:userName
                      })
        });
    } else {
      axios
        .put(`${endpoint}/api/user/${dataId}`, requestBody)
        .then(() => {
          setSubmitBtnLoading(false);
          customToast.success("User updated successfully");
          handleClose(true);
          if(dataId === userId){
            dispatch(incrementFetchUserDtlTrg())
          }
          postEventApi({  action:'updated user ' + requestBody.fullName,
                          status:'success',
                          existingRecord:JSON.stringify(newObj.updatedExistingRecord),
                          updateValue:JSON.stringify(newObj.updatedUpdateValue),
                          userId:userId,
                          userName:requestBody.id === userId ? requestBody.fullName : userName,
                          userRecordId: requestBody.id
                        })
        })
        .catch((error) => {
          handleCatchLogic(error)
          postEventApi({  action:'updated user ' + requestBody.fullName,
                          status:'failure',
                          existingRecord:JSON.stringify(newObj.updatedExistingRecord),
                          updateValue:JSON.stringify(newObj.updatedUpdateValue),
                          userId:userId,
                          userName:userName,
                          userRecordId: requestBody.id
                        })
        });
    }
  })()
  };
  // handle submit logic end

  // reset & value assign logic start
  useEffect(() => {
    if (open) {
      setState({
        ...initialState,
        ...(dataFullName && { fullName: dataFullName }),
        ...(dataEmail && { email: dataEmail }),
        ...(dataIsAdmin && { isAdmin: dataIsAdmin }),
        ...(dataPermissions && {
          permissions: dataPermissions.map((e) => JSON.stringify(e)),
        }),
      });
    }
  }, [open]);
  // reset & value assign logic end

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullScreen={fullScreen}
      fullWidth
      TransitionComponent={Transition}
    >
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
            <IconButton onClick={() => handleClose(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Full Name"
                name="fullName"
                value={state.fullName}
                onChange={handleFullNameChange}
                error={state.fullNameError ? true : false}
                helperText={state.fullNameError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Email"
                name="email"
                value={state.email}
                onChange={handleEmailChange}
                error={state.emailError ? true : false}
                helperText={state.emailError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required={!dataId}
                fullWidth
                type={state.showPassword ? "text" : "password"}
                label={!dataId ? "Password" : "Update Password"}
                name="password"
                value={state.password}
                onChange={handlePasswordChange}
                error={state.passwordError ? true : false}
                helperText={state.passwordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" onClick={handleShowPassword} sx={{ cursor: 'pointer' }}>
                      {state.showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required={!dataId}
                fullWidth
                type={state.showconfirmPassword ? "text" : "password"}
                label={"Confirm Password"}
                name="confirmPassword"
                value={state.confirmPassword}
                onChange={handleConfirmPasswordChange}
                error={state.confirmPasswordError ? true : false}
                helperText={state.confirmPasswordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" onClick={handleShowConfirmPassword} sx={{ cursor: 'pointer' }}>
                      {state.showconfirmPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* {dataId && ( */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel required>Permissions</InputLabel>
                <Select
                  multiple
                  value={state.permissions}
                  onChange={handlePermissionsChange}
                  input={<OutlinedInput label="Permissions" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value, idx) => (
                        <Chip key={idx} label={JSON.parse(value).name} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                  error={state.permissionError ? true : false}

                >
                  {permissionsList.map((option, idx) => 
                  (
                    <MenuItem key={idx} value={JSON.stringify(option)}>
                      <Checkbox
                        checked={
                          state.permissions.indexOf(JSON.stringify(option)) >
                          -1
                        }
                      />
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  )
                  )}
                </Select>
                <FormHelperText error={Boolean(true)}>{state.permissionError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.isAdmin}
                    onChange={handleIsAdminChange}
                  />
                }
                label="IsAdmin"
                disabled={userId === dataId}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            type="submit"
            disabled={isSubmitBtnDisbaled}
            loading={submitBtnLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
