// save JWT token in cookie
export const saveJWTTokenInCookie = (token, rememberMe) => {
  document.cookie = `atkn=${token}; path=/${
    rememberMe ? `; max-age=${24 * 60 * 60}` : ""
  }`;
};

// remove JWT token from cookie
export const removeJWTTokenFromCookie = () => {
  document.cookie = `atkn=; path=/; max-age=${0}`;
};

// Get JWT token from cookie
export const getJWTTokenFromCookie = () => {
  const cookieToken = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("atkn="));
  const token = cookieToken ? `Bearer ${cookieToken.substring(5)}` : undefined;
  return token;
};

// clear cookie data saved by app
export const clearCookieData = () => {
  removeJWTTokenFromCookie(); // removing token from cookie
};
