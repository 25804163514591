import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = ({ navbarColor, theme }) =>
  createTheme({
    palette: {
      primary: {
        dark: navbarColor || "#215bb2",
        main: navbarColor || "#3082ff",
        light: navbarColor || "#599bff",
      },
      secondary: {
        dark: "#215bb2",
        main: "#3082ff",
        light: "#599bff",
      },
      mode: ["dark", "light"].includes(theme) ? theme : "light",
    },
    shape: {
      borderRadius: 10,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          sizeLarge: { height: 50 },
        },
        defaultProps: {
          disableElevation: true,
        },
      },
    },
  });

export default theme;
