import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  Stack,
  Chip,
  Pagination,
  useMediaQuery,
} from "@mui/material";

import axios from "axios";
import MaterialReactTable from 'material-react-table';
//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { getEventApi }  from "../../hooks/GET/event";
import postEventApi from "../../hooks/POST/event";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useSelector, useDispatch } from "react-redux";
import { incrementFetchUserDtlTrg } from "../../utils/reduxSlice/tempSlice";
import ListDataViewDialog from "../../components/ListDataViewDialog";
import { userProfileApi } from "../../hooks/GET/users";
import tablesApi from "../../hooks/GET/tables";
import { DateField } from '@mui/x-date-pickers/DateField';
import { tablePrimaryKeyApi } from "../../hooks/GET/tablesDetail";


function Event() {
  const dispatch = useDispatch();
  //get logged-in user
  const userId = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.fullName);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  
  const isSmall2 = useMediaQuery('(max-width:600px)');

  //table pagination logic start
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const rowsPerPageOption = [25, 50, 100, 200];

  const handleChangePage = (e, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //table pagination logic end

  //  Column Filter start
  const [columnFilters, setColumnFilters] = useState([]);

  useEffect(() => {
    if(columnFilters.length > 0){
      setColumnFilters(columnFilters);
      setPage(0);
      setIsLoading(true);
    }
  }, [columnFilters])
  //  Column Filter end

  // Table List logic start
  const [tableList, setTableList] = useState([]);
  useEffect(() => {
    tablesApi()
      .then((data) => {
        setTableList(data);
      })
      .catch((error) => console.log(error)); 
  },[])
  // Table List logic end

  const [refetchUsersList, setRefetchUsersList] = useState(0);
  const [eventsData, setEventsData] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getEventApi(page,rowsPerPage,columnFilters)
      .then((data) => {
        // get tablesName in the existingRecord and updateValue
        for (let i = 0; i < data[0].length; i++) {
          if(data[0][i].existingRecord.hasOwnProperty("tables")){
            data[0][i].existingRecordTablesId = data[0][i].existingRecord.tables
            data[0][i].existingRecord.tables = tableList.filter(item => data[0][i].existingRecord.tables.includes(item.id)).map(item => item.name)
          }
          if(data[0][i].updateValue.hasOwnProperty("tables")){
            data[0][i].updateValueTablesId = data[0][i].updateValue.tables
            data[0][i].updateValue.tables = tableList.filter(item => data[0][i].updateValue.tables.includes(item.id)).map(item => item.name)
          }
        }
        setEventsData(data[0]);
        setTotalResult(data[1]);
        if (data[0].length > 0){
          getEventsColumns(Object.keys(data[0][0]),tableList)
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [refetchUsersList,page,rowsPerPage,columnFilters,tableList]);

  // table Columns logic start
  const [eventsColumns, setEventsColumns] = useState([]);
  const getEventsColumns = (columnsList,tableList) => {
    const wantedItems = ["id","action","status","userName","existingRecord","updateValue","createdAt"]
    columnsList = columnsList.filter(item => wantedItems.includes(item));

    var list = []
    columnsList.forEach((col) => {
    var columnsListObj
    if(col === 'existingRecord' || col === 'updateValue'){
      columnsListObj = { 
        'accessorKey':col,
        'header':col, 
        accessorFn: (row) =>  {
          if(row[col].hasOwnProperty("password")){
            row[col].password = "updated";
          }
          if(row[col].hasOwnProperty("updatePassword")){
            delete row[col].updatePassword;
            delete row[col].verifyPassword;
            row[col].password = "updated";          
          }
          const arr = Object.entries(row[col]).map(([key, value]) => {
            if(key === 'password'){
              return `${key} ${value}`;
            }
            else{
              if (key === "tables" && Array.isArray(value) ) {
                value = value.map(item => item.trim()).join(', ');
              }
              return `${key}: ${value}`;
            }
          });
          const totalCharacters = arr.reduce((acc, curr) => acc + curr.length, 0);

          return totalCharacters > 50 ? 
          <div>
            {arr[0].length < 50 ?
            <Chip label={arr[0]} size="small" />
            : null
            }
          {arr[0].length > 50 ?
              <Button size="small" sx={{ textTransform: 'capitalize', fontSize: 10 }} onClick={() => handleViewAllDialogOpen(arr,col)}>View All</Button>
            :(
          arr.length > 1?
            <Button size="small" sx={{ textTransform: 'capitalize', fontSize: 10 }} onClick={() => handleViewAllDialogOpen(arr,col)}>View All</Button>
            : null
            )
          }
            </div>
          :
          arr.map((pv, pvi) => 
            (
            <Chip label={pv} key={pvi} size="small" sx={{marginTop: '5px'}}/>
            )
          )
        }
      }
    }
    else if(col === 'createdAt'){
      columnsListObj = {
        'accessorKey':col,
        'header':col, 
        accessorFn: (row) => {
          return new Date(row.createdAt).toISOString().slice(0, 10)
        },
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              format="YYYY-MM-DD"
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              value={column.getFilterValue()}
            />
              </LocalizationProvider>
            ),
      }
    }
    else{
      columnsListObj = { 'accessorKey':col,'header':col}
    }

    list.push(columnsListObj);
  })  
    const buttonObj = {
      accessorKey: 'revert',
      header: 'Revert',
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      enableHiding: true,
      Cell: ({row}) => {
        return (
        <Stack direction="row" justifyContent="left" >
        <span>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            disabled={!isAdmin}
            onClick={() =>
              handleRevertDialogOpen(
                "Are you sure you want to revert this record?",
                row.original,
                row.original.id
              )
            }
          >
              Revert
          </Button>
          </span>
        </Stack>
      )},
    }
    list.push(buttonObj);

    setEventsColumns(list)
  };
  // table Columns logic end
  
  const [userProfileData, setUserProfileData] = useState({});
  const [userProfileId, setUserProfileId] = useState(0);

  useEffect(() => {
    if(userProfileId > 0){
      userProfileApi(userProfileId)
          .then((data) => {
            setUserProfileData(data)
          })
          .catch((error) => console.log(error));
    }
  },[userProfileId])

  // access level logic start
  const [accessLevel, setAccessLevel] = useState([]);
  const [tablePK, setTablePK] = useState([]);
  const [databaseName, setDatabaseName] = useState();
  useEffect(() => {
    tablePrimaryKeyApi(databaseName)
      .then((data) => {
        setTablePK(data[0])
        if( data.length === 0){
          setAccessLevel("Read only")
        }
        else{
          setAccessLevel("Read-Write")
        }
      })
      .catch((error) => console.log(error));
      
  }, [databaseName]);
  // access level list logic end

  // revert logic start
  const handleRevert = (revertDialogState) => {
    const action = revertDialogState.action;
    const tableDatabaseName = revertDialogState.tableDatabaseName;
    const existingRecord = revertDialogState.existingRecord;
    const updateValue = {...revertDialogState.updateValue};
    const apiCall = (method, url, data) => {
      return axios({
        method,
        url: `${endpoint}/api/${url}`,
        data
      });
    };

    const apiUrl = (action,tableDatabaseName) => {
      switch (true) {
        case action.includes('table'):
          return "table";
        case action.includes('user'):
          return "user";
        case action.includes('record'):
          return "tables/" + tableDatabaseName;
        default:
          throw new Error(`Invalid HTTP method`);
      }
    };

    // update and create id
    var apiUrlId;
    if (action.includes('updated table') || action.includes('created table')){
      apiUrlId = "/" + revertDialogState.tableId;
    }
    else if (action.includes('updated user') || action.includes('created user')){
      apiUrlId = "/" + revertDialogState.userRecordId;
    }
    else if (action.includes('updated a record') || action.includes('created a record')){
      apiUrlId = "/" + revertDialogState.tableDatabaseRecordId;
    }

    // generate the requestBody
    var requestBody = {}
    requestBody = {...existingRecord};
    if (action.includes('updated table')){
      const matched = tableList.find(item => item.id === revertDialogState.tableId);

      if (!existingRecord.hasOwnProperty("name")) {
        if(matched.name !== revertDialogState.tableName){
          requestBody.name = matched.name;
        }
        else{
          requestBody.name = revertDialogState.tableName;
        }
      }
      delete requestBody['accessLevel']
      if (existingRecord.hasOwnProperty("databaseName")) {
        requestBody.accessLevel = accessLevel
      }

      // updateValue
      const diff = {};
      for (const key in matched) {
        if (requestBody.hasOwnProperty(key) && matched[key] !== requestBody[key]) {
          diff[key] = matched[key];
        }
      }
      if (Object.keys(diff).length === 0){
        revertDialogState.updateValue = revertDialogState.existingRecord
      }
      else{
        revertDialogState.updateValue = diff
      }
    }
    else if (action.includes('delete table')){
      requestBody.id = revertDialogState.tableId;
    }
    else if (action.includes('updated user')){
      const arr1 = ["email","fullName","isAdmin"]
      const newUpdateValueArr = ["email","fullName","isAdmin"]
      for (const prop in newUpdateValueArr) {
        if (!existingRecord.hasOwnProperty(newUpdateValueArr[prop])){
            requestBody[newUpdateValueArr[prop]] = userProfileData[newUpdateValueArr[prop]];
        }
      }
      if (!existingRecord.hasOwnProperty("tables")) {
        requestBody.tables = userProfileData.permissions.map((obj) => obj.id)
      }
      else{
        requestBody.tables = revertDialogState.existingRecordTablesId;
        revertDialogState.existingRecord.tables = revertDialogState.existingRecordTablesId;
      }
      if (existingRecord.hasOwnProperty("password")) {
        delete requestBody.password;
        delete revertDialogState.existingRecord.password;
      } 

      // update updateValue
      for( const prop in updateValue){
        if (arr1.includes(prop)){
          if(updateValue[prop] !== userProfileData[prop]){
            updateValue[prop] = userProfileData[prop]
          }
        }
        else if(prop === "tables"){
          const tablesList = userProfileData.permissions.map((obj) => obj.id)
          if(updateValue[prop].length !== tablesList.length){
            updateValue[prop] = tablesList
          }
          else{
            updateValue[prop] = revertDialogState.updateValueTablesId;
          }
        }
        else if(prop === "password"){
          delete updateValue.password;
        }
      }
      revertDialogState.updateValue = updateValue;   
    }
    else if (action.includes('delete user')){
      requestBody["tables"] = revertDialogState.existingRecordTablesId;
      revertDialogState.existingRecord.tables = revertDialogState.existingRecordTablesId;
    }
    else if (action.includes('created user')){
      updateValue["tables"] = revertDialogState.updateValueTablesId;
      revertDialogState.updateValue = updateValue;  
    }
    else if (action.includes('delete a record')){
      if (existingRecord.hasOwnProperty("id")) {
        delete requestBody.id;
        delete existingRecord.id;
      }
    }

    const makeApiCall = (action, tableDatabaseName, existingRecord) => {
      const url = apiUrl(action,tableDatabaseName, existingRecord)
      switch (true) {
        case action.includes('delete'):
          return apiCall('post', url, existingRecord);
        case action.includes('updated'):
          return apiCall('put', url + apiUrlId, existingRecord);
        case action.includes('created') :
          return apiCall('delete', url + apiUrlId, null);
        default:
          throw new Error(`Invalid HTTP method`);
      }
    };
  
    //  action name
    const makeAction = (action) => {
      switch (true) {
        case action.includes('delete'):
          return "created ";
        case action.includes('updated'):
          return "updated ";
        case action.includes('created'):
          return "delete ";
        default:
          return "";
      }
    };
    const makeName = (action,requestBody,revertDialogState,newId,tablePK) => {
      switch (true) {
        case action.includes('table'):
          return "table " + (requestBody.name ? requestBody.name: revertDialogState.tableName);
        case action.includes('user'):
          return "user "+ (requestBody.fullName ? requestBody.fullName: "by " + userName );
        case action.includes('delete a record'):
          return  newId === 0 ? ("a record in " + revertDialogState.tableName) :("a record " + newId + " in " + revertDialogState.tableName);
        case action.includes('created a record') || action.includes('updated a record'):
          return "a record " + tablePK + " "+ revertDialogState.tableDatabaseRecordId + " in " + revertDialogState.tableName;
        default:
          return "";
      }
    };

    const revertAction = (action, id) => {
      var mess = "revert id " + id + " - ";
      if (action.includes('revert id')){
        mess = mess + action.split("-")[1].trim();
      }
      else{
        mess= mess + action;
      }
      return mess
    };
    makeApiCall(action,tableDatabaseName,requestBody)
    .then(response => {
      customToast.success("Revert successfully");
      setRefetchUsersList(refetchUsersList + 1);

      if(revertDialogState.userRecordId === userId){
        dispatch(incrementFetchUserDtlTrg())
      }
      var newAction = makeAction(action) 
      var newName = makeName(action,requestBody,revertDialogState,response.data[tablePK],tablePK) 
      newAction = newAction + newName;
      var newRevert = revertAction(newAction,revertDialogState.id)
      postEventApi({action: newRevert,
                  status:'success',
                  tableId:requestBody.id ? requestBody.id :revertDialogState.tableId,
                  tableName:action.includes('record')? revertDialogState.tableName :(requestBody.name ? requestBody.name: revertDialogState.tableName),
                  tableDatabaseName: requestBody.databaseName ? requestBody.databaseName: revertDialogState.tableDatabaseName,
                  tableDatabaseRecordId: response.data[tablePK] ? response.data[tablePK] : revertDialogState.tableDatabaseRecordId,
                  existingRecord:JSON.stringify(revertDialogState.updateValue),
                  updateValue:JSON.stringify(revertDialogState.existingRecord),
                  userRecordId: revertDialogState.userRecordId,
                  userId:userId,
                  userName:userName
                })
    })
    .catch(error => {
      console.error(error);
      if (error.response) {
        customToast.error(error.response.data?.message);
      } else {
        customToast.error(error.message);
      }
      var newAction = makeAction(action) 
      var newName = makeName(action,requestBody,revertDialogState,0,tablePK) 
      newAction = newAction + newName;
      var newRevert = revertAction(newAction,revertDialogState.id)
      setRefetchUsersList(refetchUsersList + 1); 
      postEventApi({action: newRevert,
                  status:'failure',
                  tableId:revertDialogState.tableId,
                  tableName:revertDialogState.tableName,
                  tableDatabaseName: revertDialogState.tableDatabaseName,
                  tableDatabaseRecordId: revertDialogState.tableDatabaseRecordId,
                  existingRecord:JSON.stringify(revertDialogState.updateValue),
                  updateValue:JSON.stringify(revertDialogState.existingRecord),
                  userRecordId: revertDialogState.userRecordId,
                  userId:userId,
                  userName:userName})
    });
  };
  // revert logic end

  // revert confirmation dialog logic start
  const [revertDialogState, setRevertDialogState] = useState({
    open: false,
    title: "",
    message:"",
    value: {},
    id:0
  });

  const handleRevertDialogOpen = (title, value, id ) => {
    if(value.action.includes("updated user") ){
      setUserProfileId(value.userRecordId);
    }
    else if(value.action.includes("updated table")){
      if (value.existingRecord.hasOwnProperty("databaseName")) {
        setDatabaseName(value.existingRecord.databaseName)
      }
    }
    else if(value.action.includes("updated a record")){
      setDatabaseName(value.tableDatabaseName)
    }
    else if(value.action.includes("delete a record") || value.action.includes("created a record")){
      setDatabaseName(value.tableDatabaseName)
    }
    setRevertDialogState({
      open: true,
      title: title,
      value: value,
      id: id,
    });
  };

  const handleRevertDialogClose = (isConfirmed, id) => {
    // if confirmed than revert
    if (isConfirmed) {
      handleRevert(revertDialogState.value);
    }
    setRevertDialogState({
      open: false,
      title: "",
      message:"",
      value:{},
      id: 0,
    });
  };
  // revert confirmation dialog logic end
  const [eventsList, setEventsList] = useState({
    open: false,
    title: "",
    data: []
  });
  const handleViewAllDialogOpen = (data,title) => {
    setEventsList({
      open: true,
      title: "All " + title,
      data: data.map(item => ({ name: item }))
    });
  };
  const handleViewAllDialogClose = () => {
    setEventsList({
      open: false,
      title: "",
      data: []
    });
  };
  return (
    <Paper variant="outlined" sx={{ padding: 2 }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography sx={{ display: "contents" }}>Show</Typography>
          <TextField
            select
            name="rowsPerPage"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            size="small"
            sx={{ verticalAlign: "middle", margin: 1, minWidth: 75 }}
          >
            {rowsPerPageOption.map((option, idx) => (
              <MenuItem key={idx} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {/* Table part */}
      <MaterialReactTable 
        columns={eventsColumns} 
        data={eventsData} 
        manualFiltering
        onColumnFiltersChange={setColumnFilters}
        state={{ 
          columnFilters,
          isLoading: isLoading,
        }}
        enableGlobalFilter={false}
        enablePagination={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableStickyHeader
        muiTableContainerProps={{ sx: { overflow: "auto", maxHeight: "56vh", width: '100%'} }}
        enableBottomToolbar={false}
      />

      <ConfirmationDialog
        open={revertDialogState.open}
        handleClose={handleRevertDialogClose}
        title={revertDialogState.title}
        message={revertDialogState.message}
        data={revertDialogState.id}
      />
      <ListDataViewDialog
        open={eventsList.open}
        title={eventsList.title}
        data={eventsList.data}
        handleClose={handleViewAllDialogClose}
      />

      {totalResult > 0 && (
        <Grid container justifyContent="center" sx={{ mt: 2 }} spacing={1}>
          <Grid item>
            <Button
              size="small"
              onClick={(e) => handleChangePage(e, page)}
              disabled={page === 0}
            >
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Pagination
              count={Math.ceil(totalResult / rowsPerPage)}
              page={page + 1}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              color="secondary"
              hidePrevButton
              hideNextButton
              size={isSmall2 ? 'small' : 'medium'}
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              onClick={(e) => handleChangePage(e, page + 2)}
              disabled={Math.ceil(totalResult / rowsPerPage) === page + 1}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

export default Event;
