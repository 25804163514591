const getUpdateValue = (existingRecord, updateValue) => {
  const updatedExistingRecord= {};
  const updatedUpdateValue = {};
  const arr = ['tables']

  for (const prop in existingRecord) {
    if(arr.includes(prop)){
      if(existingRecord[prop].length !== updateValue[prop].length){
        updatedExistingRecord[prop] = existingRecord[prop]
      }
    }
    else if (existingRecord[prop] !== updateValue[prop]) {
      updatedExistingRecord[prop] = existingRecord[prop];
    }
  }
  
  for (const prop in updateValue) {
    if(arr.includes(prop)){
      if(updateValue[prop].length !== existingRecord[prop].length){
        updatedUpdateValue[prop] = updateValue[prop]
      }
    }
    else if (updateValue[prop] !== existingRecord[prop]) {
      updatedUpdateValue[prop] = updateValue[prop];
    }
  }

  return { updatedExistingRecord, updatedUpdateValue };
}


export default getUpdateValue;
