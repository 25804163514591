import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetchUserDtlTrg: 0, // to fetch user detail api
  fetchBrandingDtlTrg: 0, // to fetch branding api
};

export const tempSlice = createSlice({
  name: "temp",
  initialState,
  reducers: {
    incrementFetchUserDtlTrg: (state) => {
      state.fetchUserDtlTrg += 1;
    },
    incrementFetchBrandingTrg: (state) => {
      state.fetchBrandingDtlTrg += 1;
    },
  },
});

// actions export
export const { incrementFetchUserDtlTrg, incrementFetchBrandingTrg } =
  tempSlice.actions;

export default tempSlice.reducer;
