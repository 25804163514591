import React from "react";
import {
    Dialog,

    DialogTitle,

    Slide,

    Stack,
    Chip,
    Grid,
    Typography,
    IconButton,

} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function ListDataViewDialog(props) {


    // props value
    const { open, title, data, handleClose } = props;

    return (
        <Dialog
            open={open}
            scroll="paper"
            TransitionComponent={Transition}
            onClose={handleClose}
        >
            <DialogTitle>
                <Grid container justifyContent="space-between" flexWrap={'nowrap'}>
                    <Grid item><Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{title}</Typography></Grid>
                    <Grid item><IconButton size="small" onClick={() => handleClose()}>
                        <CloseIcon fontSize="small" />
                    </IconButton></Grid>
                </Grid>

            </DialogTitle>
            {title.includes("All ") ?
                <Stack direction="column" alignItems="center" spacing={1} justifyContent="center" flexWrap={'wrap'} sx={{ rowGap: 1, margin:3 }}>
                {data.map((pv, pvi) => (
                    <Chip label={pv.name} key={pvi} size="small" />
                ))}
                </Stack>
            :
                <Stack direction="row" spacing={1} justifyContent="center" flexWrap={'wrap'} sx={{ rowGap: 1, margin:3 }}>
                    {data.map((pv, pvi) => (
                        <Chip label={pv.name} key={pvi} size="small" /> 
                    ))}
                </Stack>
            }
        </Dialog>
    );
}
