import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Paper,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Link
  // capitalize,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import EmailIcon from "@mui/icons-material/Email";
import PasswordIcon from "@mui/icons-material/Password";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  app_title,
  endpoint,
  emailValidation,
  passwordValidation,
} from "../config";
import wallpaper from "../assets/img/wallpaper.jpg";
import mcs_analytics from "../assets/img/mcs_analytics.png";
import { saveJWTTokenInCookie, getJWTTokenFromCookie } from "../utils";
import { customToast } from "../lib/toastLib";
import { incrementFetchUserDtlTrg } from "../utils/reduxSlice/tempSlice";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // branding details
  const brandingDtl = useSelector((state) => state.branding);

  // state logic start
  const [state, setState] = useState({
    email: "",
    emailError: "",
    password: "",
    passwordError: "",
    showPassword: false,
    rememberMe: false,
  });

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      email: value,
      emailError:
        value && !value.match(emailValidation.regex)
          ? emailValidation.message
          : "",
    });
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      password: value,
      passwordError: ""
      // passwordError:
      //   value && !value.match(passwordValidation.regex)
      //     ? passwordValidation.message
      //     : "",
    });
  };

  // const handleShowPassword = (e) => {
  //   setState({ ...state, showPassword: e.target.checked });
  // };
  const handleShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleRememberMe = (e) => {
    setState({ ...state, rememberMe: e.target.checked });
  };
  // state logic end

  // submit button disable state logic start
  const [isSubmitBtnDisbaled, setIsSubmitBtnDisbaled] = useState(true);

  useEffect(() => {
    setIsSubmitBtnDisbaled(
      (!state.email || state.emailError ? true : false) ||
      (!state.password || state.passwordError ? true : false)
    );
  }, [state]);
  // submit button disable state logic end

  // handle submit logic start
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestBody = {
      username: state.email,
      password: state.password,
    };
    setSubmitBtnLoading(true);
    axios
      .post(`${endpoint}/api/user/login`, requestBody)
      .then((res) => res.data)
      .then((data) => {
        setSubmitBtnLoading(false);
        saveJWTTokenInCookie(data.token, state.rememberMe); // saving token to cookie
        dispatch(incrementFetchUserDtlTrg()); // resulting to user detail fetch
        navigate("/dashboard", { replace: true });
      })
      .catch((error) => {
        setSubmitBtnLoading(false);
        if (error.response) {

          // customToast.error(error.response.data?.message);
          if (error.response.data?.message.includes("Email"))
            setState({ ...state, emailError: error.response.data?.message });
          else if (error.response.data?.message.includes("Password"))
            setState({ ...state, passwordError: error.response.data?.message });
          else customToast.error(error.response.data?.message);
            setIsSubmitBtnDisbaled(true);
        } else {
          // console.log('resp2');
          customToast.error(error.message);
        }
      });
  };
  // handle submit logic end

  // if already logged in redirect to dashboard start
  const authToken = getJWTTokenFromCookie();

  if (authToken) {
    return <Navigate to="/dashboard" />;
  }
  // if already logged in redirect to dashboard end
  return (
    <>
      <Helmet>
        <title>Login | {brandingDtl.pageTitle || app_title}</title>
      </Helmet>
      <Box
        style={{
          backgroundImage: `url(${brandingDtl.wallpaperUrl || wallpaper})`,
          backgroundSize: "cover",
          backfroundRepeat: "no-repeat",
          height: "100vh",
          width: "100vw",
          overflowX: "auto",
        }}
      >
        <Grid
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            paddingY: 2,
            minHeight: "100vh",
            width: { xs: "initial", md: "50vw" },
            float: { xs: "initial", md: "right" },
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(2px)",
            WebkitBackdropFilter: "blur(2px)",
            minWidth: 300,
          }}
        >
          <Grid item sx={{ textAlign: "center" }}>
            <img
              src={brandingDtl.logoUrl || mcs_analytics}
              alt="branding_logo"
              style={{ width: "50%", borderRadius: 10 }}
            />
          </Grid>
          <Grid item sx={{ marginTop: 2 }}>
            <Typography variant="h3" color="common.white">
              Sign in
            </Typography>
          </Grid>
          <Grid
            item
            component={Paper}
            elevation={0}
            sx={{ marginY: 3, paddingY: 2, paddingX: 3, width: "75%" }}
          >
            <form onSubmit={handleSubmit} autoComplete="off">
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                placeholder="Email"
                name="email"
                value={state.email}
                onChange={handleEmailChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                error={state.emailError ? true : false}
                helperText={state.emailError}
              />
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                type={state.showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={state.password}
                onChange={handlePasswordChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={handleShowPassword} sx={{ cursor: 'pointer' }}>
                      {state.showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </InputAdornment>
                  )
                }}
                error={state.passwordError ? true : false}
                helperText={state.passwordError}
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    name="showPassword"
                    checked={state.showPassword}
                    onChange={handleShowPassword}
                  />
                }
                label="Show password"
                sx={{ color: "text.secondary" }}
              /> */}
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    name="rememberMe"
                    checked={state.rememberMe}
                    onChange={handleRememberMe}
                  />
                }
                label="Remember Me"
                sx={{ color: "text.secondary" }}
              />
              <LoadingButton
                size="large"
                color="secondary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={isSubmitBtnDisbaled}
                loading={submitBtnLoading}
                sx={{ marginY: 2, textTransform: 'capitalize' }}
              >
                Sign in
              </LoadingButton>
            </form>
          </Grid>
          <Grid item sx={{ marginTop: 5}}>
              <Link href="https://mcsanalytics.com/" color="common.white" gutterBottom sx={{ textDecoration: 'none'}} target="_blank">
                Powered By MCS Analytics 
              </Link>
          </Grid>
        </Grid>
      </Box>
      
    </>
  );
}

export default Login;
