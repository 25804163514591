import axios from "axios";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";

const usersApi = async () => {
  try {
    const apiLink = `${endpoint}/api/user`;
    const response = await axios.get(apiLink);
    const data = response.data;

    const dataArray = [];
    if (data) {
      data.forEach((elem) => {
        const obj = {
          id: elem.id,
          fullName: elem.fullName || "",
          email: elem.email || "",
          isAdmin: elem.isAdmin || false,
          permissions:
            elem.permissions.map((e) => ({
              id: e.id,
              name: e.name || "",
              description: e.description || "",
              databaseName: e.databaseName || "",
            })) || [],
          password: elem.password
        }; 
        dataArray.push(obj);
      });
    }

    return dataArray;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

const userProfileApi= async (id) => {
  try {
    const apiLink = `${endpoint}/api/user/${id}`;
    const response = await axios.get(apiLink);
    const data = response.data;

    return data;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

export { usersApi, userProfileApi };
