import axios from "axios";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";

const postEventApi = async (requestBody) => {
  axios
  .post(`${endpoint}/api/event`, requestBody)
  .catch((error) => {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  });
};

export default postEventApi;
