import { toast } from "react-toastify";
import { Alert } from "@mui/material";

const buildOptions = (options) => {
  return {
    ...(options && { ...options }),
    hideProgressBar: true,
    closeButton: false,
    style: { padding: 0, borderRadius: 10, minHeight: 0 },
    bodyStyle: { padding: 0 },
  };
};

const customToast = {
  success(msg, options) {
    return toast(
      ({ closeToast }) => (
        <Alert severity="success" onClose={closeToast}>
          {msg}
        </Alert>
      ),
      buildOptions(options)
    );
  },
  error(msg, options) {
    return toast(
      ({ closeToast }) => (
        <Alert severity="error" onClose={closeToast}>
          {msg}
        </Alert>
      ),
      buildOptions(options)
    );
  },
  info(msg, options) {
    return toast(
      ({ closeToast }) => (
        <Alert severity="info" onClose={closeToast}>
          {msg}
        </Alert>
      ),
      buildOptions(options)
    );
  },
  warning(msg, options) {
    return toast(
      ({ closeToast }) => (
        <Alert severity="warning" onClose={closeToast}>
          {msg}
        </Alert>
      ),
      buildOptions(options)
    );
  },
};

export { toast, customToast };
