import axios from "axios";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";
import { initialState } from "../../utils/reduxSlice/brandingSlice";

const brandingApi = async () => {
  try {
    const brandingObj = { ...initialState };
    const apiLink = `${endpoint}/api/branding`;
    const response = await axios.get(apiLink);
    const data = response.data;

    if (data && data.length) {
      brandingObj.id = data[0].id || "";
      brandingObj.logoUrl = data[0].logoUrl || "";
      brandingObj.navbarColor = data[0].navbarColor || "";
      brandingObj.theme = data[0].theme || "";
      brandingObj.wallpaperUrl = data[0].wallpaperUrl || "";
      brandingObj.pageTitle = data[0].pageTitle || "";
      brandingObj.pageIconUrl = data[0].pageIconUrl || "";
    }

    return brandingObj;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

export default brandingApi;
