import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { nameValidation, descriptionValidation, endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";
import tablesApi from "../../hooks/GET/tables";
import { allTablesListApi, tablePrimaryKeyApi } from "../../hooks/GET/tablesDetail";
import postEventApi from "../../hooks/POST/event";
import { useSelector } from "react-redux";
import getUpdateValue from "../../config/getUpdateValue.js"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function TableDialog(props) {

  //get logged-in user
  const userId = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.fullName);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };
  const [databaseNameList, setDatabaseNameList] = useState([]);
  
  // props value
  const { open, handleClose, dataId, dataName, dataDescription, dataDatabaseName, dataAccessLevel } = props;
  // state logic start
  const initialState = {
    name: "",
    nameError: "",
    databaseName: [],
    databaseError:"",
    description: "",
    descriptionError: "",
  };
  const [state, setState] = useState({ ...initialState });
  const handleNameChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      name: value,
      nameError:
        value && !value.match(nameValidation.regex)
          ? nameValidation.message
          : "",
    });
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      description: value,
      descriptionError:
        value && !value.match(descriptionValidation.regex)
          ? descriptionValidation.message
          : "",
    });
  };

  // Table List logic start
  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    tablesApi()
      .then((data) => {
        setTableList(data);
      })
      .catch((error) => console.log(error));  
  }, []);
  
  const initialTableList = (databaseNameList,tableList) => {
    const nameList = databaseNameList.map(v => ({...v, disabled: false}))
      nameList.map((x) => { 
        var result = tableList.filter( item => item.databaseName === x.name);
        if (result.length > 0) {
          x.disabled = true;
        }
          return x ;
      })
    return nameList
  }

  const updateTableLlist = (databaseNameList,tableList) => {
    tablesApi()
      .then((data) => {
        setTableList(data);
      })
      .catch((error) => console.log(error)); 
    const initial= initialTableList(databaseNameList,tableList)
    return initial
  }
   // Table List logic end
   
  const handleDatabaseNameChange = (e) => {
    const value = e.target.value;
    for(let i=0; i < databaseNameList.length; i++){
        if (state.databaseName[0] !== undefined){
          if (databaseNameList[i].name === JSON.parse(value).name){
            databaseNameList[i].disabled = true
          }
          else if (databaseNameList[i].name === JSON.parse(state.databaseName[0]).name){
            databaseNameList[i].disabled = false
          }
        }
    }
    setState({
      ...state,
      databaseName: typeof value === "string" ? value.split(",") : value,
      databaseNameList: databaseNameList
    });
  };
  // state logic end

  // submit button disable state logic start
  const [isSubmitBtnDisbaled, setIsSubmitBtnDisbaled] = useState(true);

  useEffect(() => {
    setIsSubmitBtnDisbaled(
      (!state.name || state.nameError ? true : false) ||
        (!state.description || state.descriptionError ? true : false) ||
        state.databaseName.length === 0
    );
  }, [state]);
  // submit button disable state logic end

  // permissions list logic start
  useEffect(() => {
    allTablesListApi()
      .then((data) => {
        const datalist = initialTableList(data,tableList);
        setDatabaseNameList(datalist);
      })
      .catch((error) => console.log(error));
      
  }, [tableList]);
  // permissions list logic end
  
  // access level logic start
  const [accessLevel, setAccessLevel] = useState([]);
  useEffect(() => {
    const databaseName = (state.databaseName.map((e) => JSON.parse(e).name))[0]
    tablePrimaryKeyApi(databaseName)
      .then((data) => {
        if( data.length === 0){
          setAccessLevel("Read only")
        }
        else{
          setAccessLevel("Read-Write")
        }
      })
      .catch((error) => console.log(error));
      
  }, [tableList,state.databaseName]);
  // access level list logic end

  // handle Close logic start
  const handleClose2 = (value) => {

    handleClose(value)
    setDatabaseNameList(initialTableList(databaseNameList,tableList));
  }
  // handle Close logic end

  // handle submit logic start
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const handleCatchLogic = (error) => {
      setSubmitBtnLoading(false);
      if (error.response) {
        customToast.error(error.response.data?.message);
      } else {
        customToast.error(error.message);
      }
    };
    const existingRecord = {
      name: props.dataName,
      description: props.dataDescription,
      databaseName: props.dataDatabaseName,
      accessLevel: props.dataAccessLevel,
    }
    const requestBody = {
      name: state.name,
      description: state.description,
      databaseName: (state.databaseName.map((e) => JSON.parse(e).name))[0],
      accessLevel: accessLevel
    };
    const { updatedExistingRecord,updatedUpdateValue } = getUpdateValue(existingRecord,requestBody)

    setSubmitBtnLoading(true);
    if (!dataId) {
      axios
        .post(`${endpoint}/api/table`, requestBody)
        .then((response) => {
          setSubmitBtnLoading(false);
          customToast.success("Table added successfully");
          setDatabaseNameList(updateTableLlist(databaseNameList,tableList,requestBody.databaseName))
          handleClose(true);
          postEventApi({  action:'created table ' + requestBody.name,
                          status:'success',
                          tableId:response.data.id,
                          tableName:requestBody.name,
                          tableDatabaseName: requestBody.databaseName,
                          updateValue:JSON.stringify(requestBody),
                          userId:userId,
                          userName:userName
                        })
        })
        .catch((error) => {
            handleCatchLogic(error);
            postEventApi({action:'created table '+ requestBody.name,
                          status:'failure',
                          tableName:requestBody.name, 
                          tableDatabaseName: requestBody.databaseName,
                          updateValue:JSON.stringify(requestBody),
                          userId:userId,
                          userName:userName})
          });
    } else {
      axios
        .put(`${endpoint}/api/table/${dataId}`, requestBody)
        .then(() => {
          setSubmitBtnLoading(false);
          customToast.success("Table updated successfully");
          handleClose(true);
          postEventApi({action:'updated table '+ state.name ,
                      status:'success',
                      tableId:dataId,
                      tableName:requestBody.name,
                      tableDatabaseName: requestBody.databaseName,
                      existingRecord:JSON.stringify(updatedExistingRecord),
                      updateValue:JSON.stringify(updatedUpdateValue),
                      userId:userId,
                      userName:userName})
        })
        .catch((error) => {
          handleCatchLogic(error)
          postEventApi({action:'updated table '+ existingRecord.name,
                        status:'failure',
                        tableId:dataId,
                        tableName:existingRecord.name,
                        tableDatabaseName: existingRecord.databaseName,
                        existingRecord:JSON.stringify(updatedExistingRecord),
                        updateValue:JSON.stringify(updatedUpdateValue),
                        userId:userId,
                        userName:userName})
        });
    }
  };
  // handle submit logic end

  // reset & value assign logic start
  useEffect(() => {
    if (open) {
      setState({
        ...initialState,
        ...(dataName && { name: dataName }),
        ...(dataDescription && { description: dataDescription }),
        ...(dataDatabaseName && { databaseName: dataDatabaseName }),
        ...(dataDatabaseName && {
          databaseName: [{"name":dataDatabaseName }].map((e) => JSON.stringify(e)),
        }),
      });
      setDatabaseNameList(updateTableLlist(databaseNameList,tableList));
    }
  }, [open]);
  // reset & value assign logic end
  
  return (
    <Dialog
      open={open}
      scroll="paper"
      fullScreen={fullScreen}
      fullWidth
      TransitionComponent={Transition}
    >
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
            <IconButton onClick={() => handleClose2(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Name"
                name="name"
                value={state.name}
                onChange={handleNameChange}
                error={state.nameError ? true : false}
                helperText={state.nameError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Description"
                name="description"
                value={state.description}
                onChange={handleDescriptionChange}
                error={state.descriptionError ? true : false}
                helperText={state.descriptionError}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel required>Database Table Name</InputLabel>
                <Select
                  value={state.databaseName.length === 0 ? '' : state.databaseName}
                  onChange={handleDatabaseNameChange}
                  input={<OutlinedInput label="Database Table Name" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value, idx) => (
                        <Chip key={idx} label={JSON.parse(value).name}/> 
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                  error={state.databaseNameError ? true : false}
                  defaultValue = ""
                >
                  {databaseNameList.map((option, idx) => (
                    <MenuItem key={idx} value={JSON.stringify({'name':option.name})} disabled={option.disabled}>
                    <Checkbox
                        checked={
                          state.databaseName.indexOf(JSON.stringify({'name':option.name})) >
                          -1
                        }
                      />
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={Boolean(true)}>{state.databaseNameError}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            type="submit"
            disabled={isSubmitBtnDisbaled}
            loading={submitBtnLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
