import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  IconButton,
  TextField
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

import {
  integerValidation,
  floatValidation,
  dateValidation,
  endpoint,
} from "../config";
import { customToast } from "../lib/toastLib";
import {
  tablesColumnsDatatypeApi,
} from "../hooks/GET/tablesDetail";

import { useSelector } from "react-redux";
import postEventApi from "../hooks/POST/event";
import getUpdateValue from "../config/getUpdateValue.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function TableDialog(props) {
  //get logged-in user
  const userId = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.fullName);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // props value
  const {
    open,
    tableInfo,
    dataId,
    dataDatabaseName,
    editValue,
    dataPK,
    handleClose,
  } = props;
  const columnsList = Object.keys(editValue).filter(
    (elem) =>
      ![
        "open",
        "ID",
        "databaseName",
        "colsFilterOpionsFirst",
      ].includes(elem)
  );
  // state logic start
  const [state, setState] = useState({});
  const [errorMessage, setErrorMessage] = useState({});

  // reset & value assign logic start
  useEffect(() => {
    let stateValue = {};

    columnsList.forEach((col) => {
      stateValue[col] = editValue[col];
    });
    let errorValue = columnsList.reduce((a, v) => ({ ...a, [v]: "" }), {});

    if (open) {
      setState(stateValue);
      setErrorMessage(errorValue);
    }
  }, [open]);
  // reset & value assign logic end

  // table Column Data Type logic start
  const [colDataType, setColDataType] = useState([]);

  useEffect(() => {
    if (open) {
      tablesColumnsDatatypeApi(dataDatabaseName)
        .then((data) => {
          setColDataType(data);
        })
        .catch((error) => console.log(error));
    }
  }, [open]);
  // table Column Data Type logic start

  // table List state logic start
  const handleNameChange = (col) => (e) => {
    const value = e.target.value;
    const obj = { ...state };
    obj[col] = value;
    setState(obj);

    // integer
    let validation;
    if (colDataType[col].data_type.includes("int")) {
      validation = integerValidation;
    }
    // float
    else if (colDataType[col].data_type.includes("float")) {
      validation = floatValidation;
    }
    // date
    else if (colDataType[col].data_type.includes("date")) {
      validation = dateValidation;
    }

    let errorValue = { ...errorMessage };
    if (validation) {
      if (value && !value.match(validation.regex)) {
        errorValue[col] = validation.message;
      }  else {
        errorValue[col] = "";
      }
      setErrorMessage(errorValue);
    }
  };
  // table List state logic end

  // submit button disable state logic start
  const [isSubmitBtnDisbaled, setIsSubmitBtnDisbaled] = useState(true);

  useEffect(() => {
    let value = true;

    const hasValue = !Object.values(state).every((x) => x === null || x === "");
    const hasError = !Object.values(errorMessage).every(
      (x) => x === null || x === ""
    );

    if (hasError || !hasValue) {
      value = true;
    } else {
      value = false;
    }

    setIsSubmitBtnDisbaled(value);
  }, [state]);
  // submit button disable state logic end

  // handle submit logic start
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const handleCatchLogic = (error) => {
      setSubmitBtnLoading(false);
      if (error.response) {
        customToast.error(error.response.data?.message);
      } else {
        customToast.error(error.message);
      }
    };

    const requestBody = {};
    const existingRecord = {};
    let ele = 0;
    columnsList.forEach((col) => {
      existingRecord[col] = props.editValue[col]
      if (colDataType[col].data_type.includes("int")) {
        ele = state[col];
        requestBody[col] = parseInt(ele);
      }
      // float
      else if (colDataType[col].data_type.includes("float")) {
        ele = state[col];
        requestBody[col] = parseFloat(ele);
      }
      // Date
      else if (colDataType[col].data_type.includes("date")) {
        if (typeof state[col] === 'string'){
          requestBody[col] = state[col];
        }
        else{
          var date = new Date(state[col] + "GMT-5");
          requestBody[col] = Date.parse(date);
        }
        
      } else {
        // string
        if (state[col] === "" || state[col] === 0) {
          requestBody[col] = null;
        } else {
          requestBody[col] = state[col];
        }
      }
    });
    setSubmitBtnLoading(true);

    // create
    if (!dataId) {
      handleClose(true);
      axios
        .post(`${endpoint}/api/tables/${dataDatabaseName}`, requestBody)
        .then((response) => {
          setSubmitBtnLoading(false);
          customToast.success("Table added successfully");
          handleClose(true);
          postEventApi({
            action: "created a record " + dataPK + " " + response.data[dataPK] + " in " + tableInfo.name,
            status: "success",
            tableId: tableInfo.id,
            tableName: tableInfo.name,
            tableDatabaseName: dataDatabaseName,
            tableDatabaseRecordId: response.data[dataPK],
            updateValue: JSON.stringify(requestBody),
            userId: userId,
            userName: userName,
          });
        })
        .catch((error) => {
          handleCatchLogic(error);
          postEventApi({
            action: "created a record in " + tableInfo.name,
            status: "failure",
            tableId: tableInfo.id,
            tableName: tableInfo.name,
            tableDatabaseName: dataDatabaseName,
            updateValue: JSON.stringify(requestBody),
            userId: userId,
            userName: userName,
          });
        });
    } else {
      // update
      axios
        .put(
          `${endpoint}/api/tables/${dataDatabaseName}/${dataId}`,
          requestBody
        )
        .then(() => {
          setSubmitBtnLoading(false);
          customToast.success("Table updated successfully");
          handleClose(true);
          postEventApi({
            action: "updated a record " + props.dataPK + " " + dataId + " in " + tableInfo.name, 
            status: "success",
            tableId: tableInfo.id,
            tableName: tableInfo.name,
            tableDatabaseName: dataDatabaseName,
            existingRecord: JSON.stringify(existingRecord),
            updateValue: JSON.stringify(requestBody),
            tableDatabaseRecordId: dataId,
            userId: userId,
            userName: userName,
          });
        })
        .catch((error) => {
          handleCatchLogic(error);
          postEventApi({
            action: "updated a record " + props.dataPK + " " + dataId + " in " + tableInfo.name, 
            status: "failure",
            tableId: tableInfo.id,
            tableName: tableInfo.name,
            tableDatabaseName: dataDatabaseName,
            existingRecord: JSON.stringify(existingRecord),
            updateValue: JSON.stringify(requestBody),
            tableDatabaseRecordId: dataId,
            userId: userId,
            userName: userName,
          });
        });
    }
  };
  // handle submit logic end

  // handle Close logic start
  const handleClose2 = (value) => {
    handleClose(value);
    const initiaState = columnsList.reduce((accumulator, value) => {
      return { ...accumulator, [value]: "" };
    }, {});
    setState(initiaState);
  };
  // handle Close logic end

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullScreen={fullScreen}
      fullWidth
      TransitionComponent={Transition}
    >
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
            <IconButton onClick={() => handleClose2(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container justifyContent="center" spacing={3}>
            {columnsList.map((col, idx) => (
              <Grid item xs={12} key={idx}>
                <TextField
                  fullWidth
                  label={col}
                  name={col}
                  value={state[col] || ""}
                  onChange={handleNameChange(col)}
                  error={errorMessage[col] ? true : false}
                  helperText={errorMessage[col]}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            type="submit"
            disabled={isSubmitBtnDisbaled}
            loading={submitBtnLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
