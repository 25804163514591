import React from "react";
import Header from "./Header";
import { Outlet, Navigate } from "react-router-dom";
import { getJWTTokenFromCookie } from "../utils";

export default function Layout() {
  // if not logged in redirect to login start
  const authToken = getJWTTokenFromCookie();

  if (!authToken) {
    return <Navigate to="/" />;
  }
  // if not logged in redirect to login end

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}
