import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  logoUrl: "",
  navbarColor: "",
  theme: "",
  wallpaperUrl: "",
  pageTitle: "",
  pageIconUrl: "",
};

export const brandingSlice = createSlice({
  name: "branding",
  initialState,
  reducers: {
    updateBrandingDetail: (state, action) => {
      state.id = action.payload.id || "";
      state.logoUrl = action.payload.logoUrl || "";
      state.navbarColor = action.payload.navbarColor || "";
      state.theme = action.payload.theme || "";
      state.wallpaperUrl = action.payload.wallpaperUrl || "";
      state.pageTitle = action.payload.pageTitle || "";
      state.pageIconUrl = action.payload.pageIconUrl || "";
    },
  },
});

// actions export
export const { updateBrandingDetail } = brandingSlice.actions;

// initial state export
export { initialState };

export default brandingSlice.reducer;
