import React, { useState } from "react";
import { Box, Grid, Tab, useMediaQuery } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
// useSearchParams
import { Navigate } from "react-router-dom";

import { app_title } from "../../config";
import Loading from "../../components/Loading";

import Profile from "./Profile";
import Branding from "./Branding";

function Settings() {
  // user detail
  const user = useSelector((state) => state.user);

  // branding details
  const brandingDtl = useSelector((state) => state.branding);
  // media query for mobile screen
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // tab value logic start
  const [value, setValue] = useState(
    "Profile"
  );
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  // tab value logic end

  return (
    <Box
      sx={{
        overflow: "auto",
        paddingX: { xs: 1, sm: 5 },
        paddingY: 3,
        minHeight: "82vh",
      }}
    >
      <Helmet>
        <title>
          Settings - {value} | {brandingDtl.pageTitle || app_title}
        </title>
      </Helmet>
      <Grid container sx={{ minWidth: 300 }}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          {!user.id ? (
            <Loading />
          ) : (
            <TabContext value={value}>
              <TabList
                onChange={handleChange}
                centered={!isMobile}
                variant={isMobile ? "scrollable" : "standard"}
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab
                  label="Profile"
                  icon={<ManageAccountsIcon />}
                  iconPosition="start"
                  value="Profile"
                />
                {user.isAdmin && (
                  <Tab
                    label="Branding"
                    icon={<DisplaySettingsIcon />}
                    iconPosition="start"
                    value="Branding"
                  />
                )}
              </TabList>
              <TabPanel value="Profile">
                <Profile />
              </TabPanel>
              <TabPanel value="Branding">
                {user.isAdmin ? <Branding /> : <Navigate to="/dashboard" />}
              </TabPanel>
            </TabContext>
          )}
        </Box>
      </Grid>
    </Box>
  );
}

export default Settings;
