import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Container,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Button,
  useMediaQuery,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import mcs_analytics from "../assets/img/mcs_analytics.png";
import { clearCookieData } from "../utils/index";

export default function Header() {
  const location = useLocation();
  const isLTMedium = useMediaQuery('(max-width:900px)');

  // user detail
  const user = useSelector((state) => state.user);

  // branding details
  const brandingDtl = useSelector((state) => state.branding);

  const pages = [
    {
      label: "Dashboard",
      path: "/dashboard",
      permissionCheck: false,
      icon: <DashboardIcon />,
    },
    {
      label: "Admin",
      path: "/admin",
      permissionCheck: true,
      icon: <AdminPanelSettingsIcon />,
    },
    {
      label: "Settings",
      path: "/settings",
      permissionCheck: false,
      icon: <SettingsIcon />,
    },
  ];

  // nav bar menu drop down logic start
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (e) => {
    setAnchorElNav(e.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  // nav bar menu drop down logic end

  // user menu drop down logic start
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  // user menu drop down logic end

  // handle logout start
  const handleLogout = () => {
    clearCookieData();
    window.location = "/";
  };
  // handle logout end

  const userMenu = (
    <>
      <Tooltip title="Open user menu">
        <IconButton onClick={handleOpenUserMenu} color="inherit">
          <AccountCircle sx={{ fontSize: 45 }} />
          <Box sx={{ marginX: 1, textAlign: "left" }} >
            <Typography variant="subtitle2">{user.fullName}</Typography>
            <Typography variant="body2" sx={{ fontSize: 12, }}>
              {user.email}
            </Typography>
          </Box>
          {Boolean(anchorElUser) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "55px" }}
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleLogout}>
          <Typography sx={{ mr: 10 }}>Logout</Typography>
          <LogoutIcon />
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <AppBar position="sticky" color="primary" style={{ overflow: "auto" }} enableColorOnDark>
      <Container maxWidth="xl" style={{ paddingLeft: isLTMedium ? 5 : 24, paddingRight: isLTMedium ? 5 : 24 }}>
        <Toolbar disableGutters>
          {/* Desktop part start */}
          <Box
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
            }}
          >
            <img
              src={brandingDtl.logoUrl || mcs_analytics}
              alt="branding_logo"
              style={{ height: 45, padding: 5, marginRight: 10 }}
            />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/dashboard"
              sx={{ color: "inherit", textDecoration: "none" }}
            >
              {brandingDtl.pageTitle}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map(
              (page) =>
                (page.permissionCheck ? user.isAdmin : true) && (
                  <Button
                    key={page.label}
                    component={Link}
                    to={page.path}
                    sx={{ textTransform: "capitalize" }}
                    color="inherit"
                    startIcon={page.icon}
                    variant={
                      location.pathname.startsWith(page.path)
                        ? "contained"
                        : "text"
                    }
                  >
                    <Typography>{page.label}</Typography>
                  </Button>
                )
            )}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            {userMenu}
          </Box>
          {/* Desktop part end */}

          {/* Mobile part start */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" }, mt: "55px" }}
            >
              {pages.map(
                (page) =>
                  (page.permissionCheck ? user.isAdmin : true) && (
                    <MenuItem
                      key={page.label}
                      component={Link}
                      to={page.path}
                      disableRipple
                    >
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        color="inherit"
                        startIcon={page.icon}
                        variant={
                          location.pathname.startsWith(page.path)
                            ? "contained"
                            : "text"
                        }
                        disableRipple
                      >
                        <Typography>{page.label}</Typography>
                      </Button>
                    </MenuItem>
                  )
              )}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,              
              display: { xs: "flex", md: "none" },
              alignItems: "center",              
            }}
          >
            <img
              src={brandingDtl.logoUrl || mcs_analytics}
              alt="branding_logo"
              style={{ height: 45, padding: 5, marginRight: 10 }}
            />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/dashboard"
              sx={{ color: "inherit", textDecoration: "none" }}
            >
              {brandingDtl.pageTitle}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            {userMenu}
          </Box>
          {/* Mobile part end */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
