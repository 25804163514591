import React, { useEffect, useMemo, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Stack,
  Chip,
  TableContainer,
  Pagination,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

import UsersDialog from "./UsersDialog";
import {usersApi} from "../../hooks/GET/users";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useSelector } from "react-redux";
import ListDataViewDialog from "../../components/ListDataViewDialog";
import postEventApi from "../../hooks/POST/event";

function Users() {
  // users list logic start
  const [usersList, setUsersList] = useState([]);
  const [refetchUsersList, setRefetchUsersList] = useState(0);
  const [totalResult, setTotalResult] = useState(0);

  const isSmall = useMediaQuery('(max-width:536px)');
  const isSmall2 = useMediaQuery('(max-width:600px)');
  //get logged-in user
  const userId = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.fullName);

  useEffect(() => {
    usersApi()
      .then((data) => {
        setUsersList(data);
      })
      .catch((error) => console.log(error));
  }, [refetchUsersList]);

  const handleDelete = (id) => {
    const fieldValues = [...usersList];
    const deletedUserName = fieldValues.find(obj => obj.id === id).fullName
    var deletedTable = fieldValues.find(obj => obj.id === id)

    if(deletedTable['permissions'].length > 0){
      deletedTable.permissions = deletedTable['permissions'].map((obj) => obj.id);
    }
    deletedTable.tables = deletedTable.permissions
    
    axios
      .delete(`${endpoint}/api/user/${id}`)
      .then(() => {
        fieldValues.splice(fieldValues.findIndex(obj => obj.id === id), 1);

        setUsersList(fieldValues);

        customToast.success("User deleted successfully");
        postEventApi({action:'deleted user ' + deletedUserName,
                      status:'success',
                      existingRecord:JSON.stringify(deletedTable),
                      userId:userId,
                      userName:userName,
                      userRecordId: id
                    })
      })
      .catch((error) => {
        if (error.response) {
          customToast.error(error.response.data?.message);
        } else {
          customToast.error(error.message);
        }
        postEventApi({action:'deleted user ' + deletedUserName, 
                      status:'failure',
                      existingRecord:JSON.stringify(deletedTable),
                      userId:userId,
                      userName:userName,
                      userRecordId: id
                    })
      });
  };
  // users list logic end

  //table pagination logic start
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsPerPageOption = [5, 10, 20];

  const handleChangePage = (e, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalResult - page * rowsPerPage);
  //table pagination logic end

  // user dialog logic start
  const [userDialogState, setUserDialogState] = useState({
    open: false,
    id: 0,
    fullName: "",
    email: "",
    permissions: [],
    isAdmin: false,
    password:"",
  });

  const handleUserDialogOpen = (id, fullName, email, permissions, isAdmin, password) => {
    setUserDialogState({
      open: true,
      id: id,
      fullName: fullName,
      email: email,
      permissions: permissions,
      isAdmin: isAdmin,
      password: password
    });
  };

  const handleUserDialogClose = (doRefetch) => {
    if (doRefetch) {
      setRefetchUsersList(refetchUsersList + 1);
    }
    setUserDialogState({
      open: false,
      id: 0,
      fullName: "",
      email: "",
      permissions: [],
      isAdmin: false,
      password: "",
    });
  };
  // user dialog logic end

  // delete confirmation dialog logic start
  const [deleteDialogState, setDeleteDialogState] = useState({
    open: false,
    title: "",
    message: "",
    id: "",
  });

  const handleDeleteDialogOpen = (title, message, id) => {
    setDeleteDialogState({
      open: true,
      title: title,
      message: message,
      id: id,
    });
  };

  const handleDeleteDialogClose = (isConfirmed, id) => {
    // if confirmed than delete
    if (isConfirmed) {
      handleDelete(id);
    }
    setDeleteDialogState({
      open: false,
      title: "",
      message: "",
      id: "",
    });
  };
  // delete confirmation dialog logic end

  // search logic start
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    setPage(0);
    
  };

  const getFilteredUsersList = useMemo(() => {

    let filterdata = usersList;
    filterdata = filterdata.filter((obj) =>
      JSON.stringify(obj).match(new RegExp(search, "gi"))
    );

    setTotalResult(filterdata.length);
    if (filterdata.length !== 0) {
      const lastPage = Math.ceil(filterdata.length / rowsPerPage);
      if (lastPage === page)
        setPage(lastPage - 1);
    }
    return filterdata;
  }, [search, usersList, page, rowsPerPage]);

  // search logic end

  const [userCatgList, setuserCatgList] = useState({
    open: false,
    title: "",
    data: []
  });
  const handleViewAllDialogOpen = (data) => {
    setuserCatgList({
      open: true,
      title: "User Permissions",
      data: data
    });
  };
  const handleViewAllDialogClose = () => {
    setuserCatgList({
      open: false,
      title: "",
      data: []
    });
  };

  return (
    <Paper variant="outlined" sx={{ padding: 2 }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography sx={{ display: "contents" }}>Show</Typography>
          <TextField
            select
            name="rowsPerPage"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            size="small"
            sx={{ verticalAlign: "middle", margin: 1, minWidth: 75 }}
          >
            {rowsPerPageOption.map((option, idx) => (
              <MenuItem key={idx} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <Typography sx={{ display: "contents" }}>entries</Typography>
          <TextField
            name="search"
            value={search}
            onChange={handleSearchChange}
            placeholder="Search..."
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ verticalAlign: "middle", marginLeft: isSmall ? 0 : 1, marginTop: 1, marginRight: isSmall ? 2 : 1, marginBottom: 1 }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleUserDialogOpen(null, "", "", [],false,"")}
          >
            Create
          </Button>
        </Grid>
      </Grid>

      {/* Table part */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" >Sr. No.</TableCell>
              <TableCell align="center" >Full Name</TableCell>
              <TableCell align="center" >Email</TableCell>
              <TableCell align="center" >Is Admin</TableCell>
              <TableCell align="center" width="40%">Permissions</TableCell>
              <TableCell align="center" >Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? getFilteredUsersList.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              : getFilteredUsersList
            ).map((fieldValue, idx) => (
              <TableRow key={idx} hover>

                <TableCell align="center">{page * rowsPerPage + idx + 1}</TableCell>
                <TableCell align="center">{fieldValue.fullName}</TableCell>
                <TableCell align="center">{fieldValue.email}</TableCell>
                <TableCell align="center">
                  {fieldValue.isAdmin ? (
                    <DoneIcon color="success" fontSize="small" />
                  ) : (
                    <CloseIcon color="error" fontSize="small" />
                  )}
                </TableCell>
                <TableCell align="center" width="40%">
                  <Stack direction="row" spacing={1} justifyContent="center" flexWrap={'wrap'} sx={{ rowGap: 1 }}>
                    {fieldValue.permissions.slice(0, 3).map((pv, pvi) => (
                      <Chip label={pv.name} key={pvi} size="small" />
                    ))}
                    {fieldValue.permissions.length > 3
                      ? <Button size="small" sx={{ textTransform: 'capitalize', fontSize: 10 }} onClick={() => handleViewAllDialogOpen(fieldValue.permissions)}>View All</Button>
                      : null}
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <Stack direction="row" justifyContent="center" >
                    <Tooltip title="Edit" >
                    <span>
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() =>
                          handleUserDialogOpen(
                            fieldValue.id,
                            fieldValue.fullName,
                            fieldValue.email,
                            fieldValue.permissions,
                            fieldValue.isAdmin,
                            fieldValue.password,
                          )
                        }
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Delete" >
                    <span>
                      <IconButton
                        disabled={userId === fieldValue.id}
                        size="small"
                        color="error"
                        onClick={() =>
                          handleDeleteDialogOpen(
                            `Delete user '${fieldValue.fullName}'`,
                            "Are you sure you want to delete this user?",
                            fieldValue.id
                          )
                        }
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                      </span>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell align="center" colSpan={6}>
                  {totalResult === 0 && "No users available to display"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <UsersDialog
        open={userDialogState.open}
        handleClose={handleUserDialogClose}
        dataId={userDialogState.id}
        dataFullName={userDialogState.fullName}
        dataEmail={userDialogState.email}
        dataPermissions={userDialogState.permissions}
        dataIsAdmin={userDialogState.isAdmin}
        dataPassword={userDialogState.password}
      />

      <ConfirmationDialog
        open={deleteDialogState.open}
        handleClose={handleDeleteDialogClose}
        title={deleteDialogState.title}
        message={deleteDialogState.message}
        data={deleteDialogState.id}
      />

      <ListDataViewDialog
        open={userCatgList.open}
        title={userCatgList.title}
        data={userCatgList.data}
        handleClose={handleViewAllDialogClose}
      />

      {totalResult > 0 && (
        <Grid container justifyContent="center" sx={{ mt: 2 }} spacing={1}>
          <Grid item>
            <Button
              size="small"
              onClick={(e) => handleChangePage(e, page)}
              disabled={page === 0}
            >
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Pagination
              count={Math.ceil(totalResult / rowsPerPage)}
              page={page + 1}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              color="secondary"
              hidePrevButton
              hideNextButton
              size={isSmall2 ? 'small' : 'medium'}
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              onClick={(e) => handleChangePage(e, page + 2)}
              disabled={Math.ceil(totalResult / rowsPerPage) === page + 1}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

export default Users;
