import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  fullName: "",
  id: "",
  isAdmin: false,
  isDefault: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserDetail: (state, action) => {
      state.email = action.payload.email || "";
      state.fullName = action.payload.fullName || "";
      state.id = action.payload.id || "";
      state.isAdmin = action.payload.isAdmin || false;
      state.isDefault = action.payload.isDefault || false;
    },
  },
});

// actions export
export const { updateUserDetail } = userSlice.actions;

// initial state export
export { initialState };

export default userSlice.reducer;
