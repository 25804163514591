import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import errorImage from "../assets/img/error.svg";

class ErrorBoundary extends React.Component {
  state = { error: false };

  // Update state so next render shows fallback UI.
  static getDerivedStateFromError(error) {
    return { error: true };
  }

  // Logging the error to an error reporting service
  componentDidCatch(error, info) {
    console.log("Error: " + error);
    console.log("Info: " + info.componentStack);
    /* const errorMessageToSend = encodeURI(
      `Frontend React Error[${window.location.href}]\nError: ${error}\nInfo: ${info.componentStack}`
    ); */
  }

  render() {
    if (this.state.error) {
      // custom fallback UI
      return (
        <Grid
          container
          direction="column"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <img
            src={errorImage}
            alt="Something went wrong"
            style={{ width: "50vh", alignSelf: "center", marginBottom: "2rem" }}
          />
          <Grid item style={{ alignSelf: "center", textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              OOPS...SOMETHING WENT WRONG HERE
            </Typography>
            <Typography gutterBottom>
              The link you followed had some problem and it's broken
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => window.history.go(0)}
            >
              Reload the page
            </Button>
          </Grid>
        </Grid>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
