import React from "react";
import { Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

import { app_title } from "../config";
import page_not_found from "../assets/img/404.svg";

function Custom404() {
  // branding details
  const brandingDtl = useSelector((state) => state.branding);

  return (
    <>
      <Helmet>
        <title>404 | {brandingDtl.pageTitle || app_title}</title>
      </Helmet>
      <Grid
        container
        direction="column"
        sx={{ height: "100vh", justifyContent: "center", alignItems: "center" }}
      >
        <img
          src={page_not_found}
          alt="page not found"
          style={{ width: "40%" }}
        />
        <Typography variant="h4" sx={{ marginTop: 5 }}>
          404 | PageNotFound
        </Typography>
      </Grid>
    </>
  );
}

export default Custom404;
