import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Box,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import axios from "axios";

import image_placeholder from "../../assets/img/image_placeholder.png";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";
import brandingApi from "../../hooks/GET/branding";
import { incrementFetchBrandingTrg } from "../../utils/reduxSlice/tempSlice";
import { pageTitleValidation } from "../../config";

function Branding() {
  const themeHook = useTheme();
  const dispatch = useDispatch();

  // theme options
  const themeOptions = ["light", "dark"];

  // state logic start
  const initialState = {
    id: "",
    logo: "",
    logoPreview: "",
    wallpaper: "",
    wallpaperPreview: "",
    pageTitle: "",
    pageTitleError: "",
    pageIcon: "",
    pageIconPreview: "",
    theme: "",
    navbarColor: themeHook.palette.primary.main,
  };

  const [state, setState] = useState({ ...initialState });

  const handleLogoChange = (acceptedFile) => {
    if (acceptedFile.length) {
      setState({
        ...state,
        logo: acceptedFile[0],
        logoPreview: URL.createObjectURL(acceptedFile[0]),
      });
    } else {
      setState({ ...state, logo: "", logoPreview: "" });
    }
  };

  const handleWallpaperChange = (acceptedFile) => {
    if (acceptedFile.length) {
      setState({
        ...state,
        wallpaper: acceptedFile[0],
        wallpaperPreview: URL.createObjectURL(acceptedFile[0]),
      });
    } else {
      setState({ ...state, wallpaper: "", wallpaperPreview: "" });
    }
  };

  const handlePageTitleChange = (e) => {
    const value = e.target.value;
    setState({ 
      ...state, 
      pageTitle: value ,
      pageTitleError:
        value && !value.match(pageTitleValidation.regex)
          ? pageTitleValidation.message
          : "",
    });
  };

  const handlePageIconChange = (acceptedFile) => {
    if (acceptedFile.length) {
      setState({
        ...state,
        pageIcon: acceptedFile[0],
        pageIconPreview: URL.createObjectURL(acceptedFile[0]),
      });
    } else {
      setState({ ...state, pageIcon: "", pageIconPreview: "" });
    }
  };

  const handleThemeChange = (e) => {
    const value = e.target.value;
    setState({ ...state, theme: value });
  };

  const handleNavbarColorChange = (e) => {
    const value = e.target.value;
    setState({ ...state, navbarColor: value });
  };
  // state logic end

  // submit button disable state logic start
  const [isSubmitBtnDisbaled, setIsSubmitBtnDisbaled] = useState(true);

  useEffect(() => {
    setIsSubmitBtnDisbaled(
      (!state.pageTitle || state.pageTitleError ? true : false)
    );
  }, [state]);
  // submit button disable state logic end

  // fetching branding detail to re-populate start
  const [refetchBrandingDtl, setRefetchBrandingDtl] = useState(1);

  useEffect(() => {
    brandingApi()
      .then((data) => {
        setState((state) => ({
          ...state,
          id: data.id,
          logoPreview: data.logoUrl,
          wallpaperPreview: data.wallpaperUrl,
          pageTitle: data.pageTitle || "",
          pageIconPreview: data.pageIconUrl,
          theme: data.theme || "",
          navbarColor: data.navbarColor || themeHook.palette.primary.main,
        }));
      })
      .catch((error) => console.log(error));
  }, [refetchBrandingDtl]);
  // fetching branding detail to re-populate end

  // handle submit logic start
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const convertFileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitBtnLoading(true);
      const requestBody = {
        ...(state.logo && {
          logoName: state.logo.name,
          logoUrl: await convertFileToBase64(state.logo),
        }),
        navbarColor: state.navbarColor,
        theme: state.theme,
        ...(state.wallpaper && {
          wallpaperName: state.wallpaper.name,
          wallpaperUrl: await convertFileToBase64(state.wallpaper),
        }),
        pageTitle: state.pageTitle,
        ...(state.pageIcon && {
          pageIconName: state.pageIcon.name,
          pageIconUrl: await convertFileToBase64(state.pageIcon),
        }),
      };
      const apiLink = `${endpoint}/api/branding/${state.id}`;
      await axios.put(apiLink, requestBody);
      setSubmitBtnLoading(false);
      dispatch(incrementFetchBrandingTrg()); // resulting to branding detail fetch
      customToast.success("Branding details have been recorded successfully");
    } catch (error) {
      setSubmitBtnLoading(false);
      if (error.response) {
        customToast.error(error.response.data?.message);
      } else {
        customToast.error(error.message);
      }
    }
  };
  // handle submit logic end

  // handle reset logic start
  const [resetBtnLoading, setResetBtnLoading] = useState(false);

  const handleReset = (e) => {
    e.preventDefault();
    setResetBtnLoading(true);
    axios
      .put(`${endpoint}/api/branding/reset/${state.id}`)
      .then((res) => res.data)
      .then(() => {
        setResetBtnLoading(false);
        dispatch(incrementFetchBrandingTrg()); // resulting to branding detail fetch
        setState({ ...initialState }); // resetting branding detail
        setRefetchBrandingDtl(refetchBrandingDtl + 1); // triggering branding detail fetch
        customToast.success("Branding details reset successful");
      })
      .catch((error) => {
        setResetBtnLoading(false);
        if (error.response) {
          customToast.error(error.response.data?.message);
        } else {
          customToast.error(error.message);
        }
      });
  };
  // handle reset logic end

  return (
    <Grid container justifyContent="center">
      <Grid
        item
        component={Paper}
        variant="outlined"
        sx={{ padding: 2 }}
        xs={12}
        sm={10}
        md={8}
      >
        <Grid
          container
          component="form"
          onSubmit={handleSubmit}
          autoComplete="off"
          spacing={3}
          justifyContent="space-around"
          alignItems="flex-end"
        >
          {/* logo */}
          <Grid item xs={12} sm={5} sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6">
              Upload a logo
            </Typography>
            <Dropzone
              onDrop={handleLogoChange}
              accept={{
                "image/jpeg": [],
                "image/jpg": [],
                "image/png": [],
              }}
              maxFiles={1}
            >
              {({ getRootProps, getInputProps }) => (
                <Box
                  {...getRootProps()}
                  sx={{
                    borderWidth: 2,
                    borderStyle: "dashed",
                    borderColor: "text.secondary",
                    padding: 2,
                  }}
                >
                  <input {...getInputProps()} />
                  <img
                    alt="logo"
                    src={state.logoPreview || image_placeholder}
                    style={{ width: "40%" }}
                  />
                  <Typography color="textSecondary" sx={{ mt: 2 }}>
                    Drag {"&"} Drop Or Click to Select image
                  </Typography>
                </Box>
              )}
            </Dropzone>
          </Grid>

          {/* wallpaper */}
          <Grid item xs={12} sm={5} sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6">
              Upload a wallpaper
            </Typography>
            <Typography variant="caption" color="textSecondary">
              For a better view, please select image with ratio 16:9 {"("}e.g
              1366x768 resolution{")"}
            </Typography>
            <Dropzone
              onDrop={handleWallpaperChange}
              accept={{
                "image/jpeg": [],
                "image/jpg": [],
                "image/png": [],
              }}
              maxFiles={1}
            >
              {({ getRootProps, getInputProps }) => (
                <Box
                  {...getRootProps()}
                  sx={{
                    borderWidth: 2,
                    borderStyle: "dashed",
                    borderColor: "text.secondary",
                    padding: 2,
                  }}
                >
                  <input {...getInputProps()} />
                  <img
                    alt="wallpaper"
                    src={state.wallpaperPreview || image_placeholder}
                    style={{ width: "40%" }}
                  />
                  <Typography color="textSecondary" sx={{ mt: 2 }}>
                    Drag {"&"} Drop Or Click to Select image
                  </Typography>
                </Box>
              )}
            </Dropzone>
          </Grid>

          {/* page title */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Page Title"
              name="pageTitle"
              value={state.pageTitle}
              onChange={handlePageTitleChange}
              error={state.pageTitleError ? true : false}
              helperText={state.pageTitleError}
            />
          </Grid>

          {/* page icon */}
          <Grid item xs={12} sm={6} alignSelf="center">
            <Dropzone
              onDrop={handlePageIconChange}
              accept={{
                "image/jpeg": [],
                "image/jpg": [],
                "image/png": [],
              }}
              maxFiles={1}
            >
              {({ getRootProps, getInputProps }) => (
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid
                    item
                    {...getRootProps()}
                    sx={{
                      borderWidth: 2,
                      borderStyle: "dashed",
                      borderColor: "text.secondary",
                    }}
                    xs={2}
                    sm={3}
                    md={2}
                  >
                    <input {...getInputProps()} />
                    <img
                      alt="pageIcon"
                      src={state.pageIconPreview || image_placeholder}
                      style={{
                        width: "100%",
                        padding: 5,
                        verticalAlign: "middle",
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} sm={9} md={10} sx={{ paddingLeft: 2 }}>
                    <Typography variant="subtitle2">Page Icon</Typography>
                    <Typography color="textSecondary" fontSize={12}>
                      Drag {"&"} Drop Or Click to Select image
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Dropzone>
          </Grid>

          {/* theme */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              select
              label="Theme"
              name="theme"
              value={state.theme}
              onChange={handleThemeChange}
            >
              {themeOptions.map((option, idx) => (
                <MenuItem key={idx} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* navbar color */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="color"
              label="Navbar Color"
              name="navbarColor"
              value={state.navbarColor}
              onChange={handleNavbarColorChange}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              color="secondary"
              variant="contained"
              size="large"
              fullWidth
              type="submit"
              disabled={resetBtnLoading}
              loading={submitBtnLoading}
              sx={{ mt: 2 }}
            >
              Save
            </LoadingButton>
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              color="secondary"
              variant="outlined"
              size="large"
              fullWidth
              onClick={handleReset}
              disabled={submitBtnLoading}
              loading={resetBtnLoading}
            >
              Reset
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Branding;
