import { configureStore } from "@reduxjs/toolkit";

// slice imports
import userReducer from "../utils/reduxSlice/userSlice";
import tempReducer from "../utils/reduxSlice/tempSlice";
import brandingReducer from "../utils/reduxSlice/brandingSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    temp: tempReducer,
    branding: brandingReducer,
  },
  devTools: process.env.NODE_ENV === "development", // to disable devtools access other than development
});
