import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Slide,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ConfirmationDialog(props) {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // props value
  const { open, handleClose, title, message, data } = props;

  return (
    <Dialog
      open={open}
      scroll="paper"
      // fullScreen={fullScreen}
      TransitionComponent={Transition}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => handleClose(false, data)}
          startIcon={<CancelIcon />}
        >
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={() => handleClose(true, data)}
          startIcon={<CheckIcon />}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
